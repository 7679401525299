.story-card {
    border: 2px solid #000000; /* Dark border for the card */
    border-radius: 10px;
    margin-bottom: 1rem;
  }
  
  .story-heading {
    background-color: #000000; /* Dark background for the heading */
    color: white; /* White text color */
    padding: 10px;
    border-bottom: 2px solid #6c757d; /* Lighter border to separate header from content */
    cursor: pointer;
  }
  
  .story-content {
    padding: 20px;
  }
  
  .story-images {
    padding: 20px;
  }
  
  .image-card {
    background-color: #f8f9fa; /* Light background for images */
    border: 1px solid #ced4da; /* Subtle border for image cards */
    border-radius: 5px;
  }
  
  .story-page-container {
    padding: 2rem;
  }
  
  .block-user-select {
    user-select: none; /* Disable text selection */
  }
  