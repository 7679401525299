.App {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  /* background-image: var(--website-bg-link); */
  background-image: url("https://wife-adventures.s3.us-east-1.amazonaws.com/images/background_image.png");
  background-size: cover;
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.page-container {
  padding: 1vh 1vw;
  width: 100vw;
  bottom: 0;
  height: 93vh;
  justify-content: center; /* Center content horizontally */
  justify-items: center;
  margin: auto;
  overflow-y: visible;
  overflow-x: hidden;
  align-items: center; /* Center content vertically */
  white-space: normal; /* Allows text to wrap */
  word-wrap: break-word; /* Breaks long words to wrap */
  overflow-wrap: break-word; /* Ensures wrapping of long words */
}
/* CSS for landscape orientation */
@media only screen and (orientation: landscape) {
  
}


/* CSS for portrait orientation */

@media only screen and (orientation: portrait) {
  .page-container{
    /* font-size: larger; */
  }
}
.max-content{
  max-width: max-content;
}

.display-grid {
  display: grid;
}

textarea {
  cursor: pointer;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  transition: border-color 0.3s ease;
}

textarea:focus {
  border-color: #007bff;
}

.column-col {
  padding: 10px;
  border: 1px solid #ccc;
  word-wrap: break-word; /* Ensures long words will wrap */
  overflow-wrap: break-word; /* Ensures long words will wrap */
  word-break: break-all; /* Breaks words at boundaries */
  overflow: hidden; /* Hides overflow content */
  text-overflow: ellipsis; /* Adds ellipsis for overflow text */
  white-space: normal; /* Allows text to wrap */
}

.bg-transparent {
  background-color: transparent;
}