.active {
    background-color: blue;
    border-radius: 5px;
}
.navbar {
    color: white;
    z-index: 9;
}

.logoImage{
    padding: 0;
    margin: 0;
    align-self: center;
    z-index: 10;
    overflow-y: visible;
}

@media only screen and (orientation: portrait) {
    .navbar-nav {
        padding: 10px 10px;
    }
    .nav-item {
        height: fit-content;
    }
}