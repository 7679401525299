@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.cursor-pointer {
  cursor: pointer;
}

.block-user-select {
  user-select: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.borderRed {
  border: 2px solid red;
  z-index: 100000;
}

.image-options {
  width: auto;
  max-height: 20vh;
  overflow-y: auto;
  border: 1px skyblue solid;
  border-radius: 5px;
  background-color: rgb(250, 250, 250);
}

.bold-text {
  font-weight: bold;
}
/* styles.module.css or your global stylesheet */
.striped-row-odd {
  background-color: #f7f7f7; /* Light gray or your preferred color */
}

.striped-row-even {
  background-color: #ffffff; /* White or your preferred color */
}

/* CSS for landscape orientation */
@media only screen and (orientation: landscape) {
  
}


/* CSS for portrait orientation */

@media only screen and (orientation: portrait) {
}
